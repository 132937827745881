import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/heading/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/heading"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/heading/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/heading" storybookUrl="/?path=/story/components-heading--all-variants" abstractUrl="https://share.goabstract.com/85c81258-90b9-49c8-b34b-9418da5f2f00?" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <h3>{`About Heading`}</h3>
        <p>{`Headings should allow users to easily distinguish content sections on the page.`}</p>
        <ul>
          <li parentName="ul">{`Headings can have multiple levels of depth to help organize content`}</li>
          <li parentName="ul">{`Heading types provide specific styles to the font-size, font-weight, and line-height of the text to create hierarchy that allow users to easily scan pages and content`}</li>
          <li parentName="ul">{`Headings should be used with intention that maintains a coherent document outline`}</li>
          <li parentName="ul">{`Don’t use heading tags to resize text. Instead use the `}<inlineCode parentName="li">{`<Text>`}</inlineCode>{` primitive`}</li>
          <li parentName="ul">{`Don’t skip headline levels. Always start with `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{`, `}<inlineCode parentName="li">{`<h2>`}</inlineCode>{`, and so on`}</li>
          <li parentName="ul">{`There can only be one `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` tag per page.`}</li>
        </ul>
        <h4>{`Accessibility`}</h4>
        <p>{`It is important to not skip one or more heading levels as it is common for screen readers to build an outline of the page and assist users as they navigate a page. Skipping a heading level could cause confusion as the user navigating the page may not know why a heading is missing.`}</p>
        <ul>
          <li parentName="ul">{`Heading text should meet AA requirements (4.5:1) for color contrast from itself and the background color`}</li>
          <li parentName="ul">{`Headings should be used thoughtfully, with clear hierarchies for content and subsections`}</li>
        </ul>
        <h2>{`Examples`}</h2>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<>
<Heading as="h1" variant="heading10">
  Black lives matter
</Heading>
<Heading as="h2" variant="heading20">
  Black lives matter
</Heading>
<Heading as="h3" variant="heading30">
  Black lives matter
</Heading>
<Heading as="h4" variant="heading40">
  Black lives matter
</Heading>
<Heading as="h5" variant="heading50">
  Black lives matter
</Heading>
<Heading as="h6" variant="heading60">
  Black lives matter
</Heading>
</>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading10 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading10">
  Heading One
</Heading>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading20 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading20">
  Heading Two
</Heading>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading30 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading30">
  Heading Three
</Heading>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading40 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading40">
  Heading Four
</Heading>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading50 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading50">
  Heading Five
</Heading>`}
        </LivePreview>
        <h3>{`Heading as h2 with heading60 variant`}</h3>
        <LivePreview scope={{
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Heading as="h2" variant="heading60">
  Heading Six
</Heading>`}
        </LivePreview>
        <h3>{`Heading with no bottom margin`}</h3>
        <LivePreview scope={{
          Heading,
          Card
        }} language="jsx" mdxType="LivePreview">
  {`<Card>
  <Heading as="h2" marginBottom="space0" variant="heading10">
    Heading with no margin
  </Heading>
</Card>`}
        </LivePreview>
        <h2>{`Composition Notes`}</h2>
        <h3>{`When to use Heading`}</h3>
        <p>{`Headings should be used for page headings or content subsections. Headings are broken into six levels, where h1 is the highest level and h6 is the lowest. Avoid skipping heading levels. Use the next lowest heading level to maintain a coherent document outline.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use headings for page headings to specific sections of content." mdxType="Do" />
  <Dont title="Don't" body="Don’t use headings for pure stylistic purposes." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use headings in order, so your content reads like an outline." mdxType="Do" />
  <Dont title="Don't" body="Don’t use headings out of order." mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/heading - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Heading} from '@twilio-paste/heading';

const Component = () => (
  <Heading as="h2" variant="heading20">
    Heading Two
  </Heading>
);
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`asTags`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'label', 'span'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginBottom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`oneOf(['space0'])`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Currently we only allow `}<inlineCode parentName="td">{`space0`}</inlineCode>{` to remove bottom margin`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`variant`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`headingVariants`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'heading10', 'heading20', 'heading30', 'heading40', 'heading50', 'heading60'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      